
import Vue from 'vue';
import Moment from 'moment';
import {
  mdbRow,
  mdbCol,
  mdbContainer,
  mdbNavbar,
  mdbNavbarBrand,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbSimpleChart,
  mdbBtn,
  mdbInput,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbIcon,
  mdbLineChart,
  mdbBarChart,
  mdbProgress,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';

export default Vue.extend({
  name: 'homepage',
  components: {
    mdbContainer,
    mdbNavbar,
    mdbNavbarBrand,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbSimpleChart,
    mdbBtn,
    mdbInput,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbIcon,
    mdbLineChart,
    mdbBarChart,
    mdbProgress,
  },
  data() {
    return {
      bankaccountlatestinfos: [],
      visainputopened: false,
      newvisaamount: 0,
      physicalactivities: [],
      teslainfos: {
        odometer: 0,
        added_at: '',
      },
      waitingcalls: 4,
      showvisagraph: false,
      lineChartData: {
        labels: [],
        datasets: [
          {
            label: 'VISA',
            backgroundColor: 'rgba(255, 99, 132, 0.1)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 0.7,
            data: [],
          },
        ],
      },
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: true,
              color: 'rgba(0, 0, 0, 0.1)',
            },
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: 'rgba(0, 0, 0, 0.1)',
            },
          }],
        },
      },
      barChartDataTesla: {
        labels: [],
        datasets: [{
          label: 'Kilomètres',
          data: [],
          backgroundColor: [''],
          borderColor: [''],
          borderWidth: 1,
        }],
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            barPercentage: 1,
            gridLines: {
              display: true,
              color: 'rgba(0, 0, 0, 0.1)',
            },
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: 'rgba(0, 0, 0, 0.1)',
            },
          }],
        },
      },
    };
  },
  computed: {
    visapercentvalue():number {
      const visa:any[] = this.bankaccountlatestinfos.filter((r:any) => r.name === 'VISA');
      if (visa.length) {
        return Math.round((visa[0].lastAmount.amount / visa[0].limit) * 100);
      }
      return 0;
    },
    visaamount():number {
      const visa:any[] = this.bankaccountlatestinfos.filter((r:any) => r.name === 'VISA');
      if (visa.length) {
        return visa[0].lastAmount.amount;
      }
      return 0;
    },
    lastupdate():string {
      const visa:any[] = this.bankaccountlatestinfos.filter((r:any) => r.name === 'VISA');
      if (!visa.length) {
        return '---';
      }
      const thedate = new Date(visa[0].lastAmount.datetime);
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleDateString('fr-CA', options);
    },
    visachartcolor():string {
      if (this.visapercentvalue > 75) {
        return '#ff4444';
      }
      if (this.visapercentvalue > 50) {
        return '#FF8800';
      }
      if (this.visapercentvalue > 25) {
        return '#ffbb33';
      }
      return 'GREEN';
    },
    totalkms():number {
      return this.physicalactivities.reduce((partialSum, a:any) => partialSum + parseFloat(a.distancekm), 0);
    },
  },
  mounted() {
    this.loadBankAccountLatestInfos();
    this.loadLastPhysicalActivities();
    this.loadTeslaInfos();
    this.loadTeslaGraphInfos();
  },
  methods: {
    loadBankAccountLatestInfos(): void {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'public/bankaccount/list').then((res:any) => {
        self.bankaccountlatestinfos = res.data;
        const visa:any[] = self.bankaccountlatestinfos.filter((r:any) => r.name === 'VISA');
        if (visa.length) {
          this.lineChartData.labels = visa[0].graph30days.reduce((rv:any, x:any) => {
            // eslint-disable-next-line no-param-reassign
            (rv.data = rv.data || []);
            if (!rv.data.length) {
              rv.data.push(1);
              return rv;
            }
            rv.data.push(rv.data.at(-1) + 1);
            return rv;
          }, {}).data;
          this.lineChartData.datasets[0].data = visa[0].graph30days.map((elm:any) => elm.amount);
          self.newvisaamount = visa[0].lastAmount.amount;
        }
        self.waitingcalls -= 1;
      });
    },
    loadTeslaGraphInfos(): void {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'public/tesla/last-30-days').then((res:any) => {
        self.barChartDataTesla.datasets[0].data = res.data.map((elm:any) => Math.round((elm.endat - elm.startat) * 1.609));
        self.barChartDataTesla.labels = res.data.map((elm:any) => elm.weekday);
        self.barChartDataTesla.datasets[0].backgroundColor = Array(res.data.length).fill('rgba(54, 162, 235, 0.2)');
        self.barChartDataTesla.datasets[0].borderColor = Array(res.data.length).fill('rgba(54, 162, 235, 1)');
        self.waitingcalls -= 1;
      });
    },
    updatevisaamount(): void {
      this.visainputopened = false;
      const self = this;
      const api = new Apicall();
      api.call('POST', 'public/bankaccount/set-amount', { amount: this.newvisaamount, bankaccountuuid: this.getVisaUUID() }).then(() => {
        self.loadBankAccountLatestInfos();
      });
    },
    getVisaUUID():string {
      const visa:any[] = this.bankaccountlatestinfos.filter((r:any) => r.name === 'VISA');
      if (!visa.length) {
        return 'NOT FOUND';
      }
      return visa[0].uuid;
    },
    loadLastPhysicalActivities(): void {
      const api = new Apicall();
      const self = this;
      api.call('GET', 'public/health/list').then((res:any) => {
        self.physicalactivities = res.data;
        self.waitingcalls -= 1;
      });
    },
    formatActivityStartTime(thedate:string): string {
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Date(thedate).toLocaleDateString('fr-CA', options);
    },
    computeActivityDuration(start:string, end:string): string {
      const diff = Moment(end).diff(Moment(start));
      return Moment.utc(diff).format('H [Heure] mm [Min.]');
    },
    loadTeslaInfos():void {
      const api = new Apicall();
      const self = this;
      api.call('GET', 'public/tesla/last-odometer').then((res:any) => {
        self.teslainfos = res.data;
        self.waitingcalls -= 1;
      });
    },
  },
});
