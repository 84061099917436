import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbvue/lib/css/mdb.min.css';
import Vue from 'vue';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import App from './App.vue';
import router from './router';
import store from './store';

const firebaseConfig = {
  apiKey: 'AIzaSyDwyX667CDq4QpJmgtcrGLymVkB4Fr1H4I',
  authDomain: 'organize-me-374216.firebaseapp.com',
  projectId: 'organize-me-374216',
  storageBucket: 'organize-me-374216.appspot.com',
  messagingSenderId: '937762750544',
  appId: '1:937762750544:web:cadfa6071e7637b3039155',
  measurementId: 'G-GDHK93NNRG',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const authinited = new Promise((resolve) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      resolve(user);
    } else if (router.currentRoute.name !== 'Login') {
      // User is signed out
      router.push({ name: 'Login' });
    }
  });
});

Vue.config.productionTip = false;

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'Login') {
    await authinited;
    return next();
    /*
    const thetoken = await getIdToken(auth.currentUser);
    */
  }
  return next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
