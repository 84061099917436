import axios from 'axios';
import { getAuth, getIdToken, User } from 'firebase/auth';
import config from './Config';

export default class Apicall {
  return = {
    status: 'OK',
    data: null,
    msg: null,
  };

  header = {};

  async getHeaders() {
    const auth = getAuth();
    const thetoken = await getIdToken(auth.currentUser as User);
    this.header = {
      headers: {
        'Accept-Language': 'fr',
        Accept: 'application/json',
        Authorization: `Bearer ${thetoken}`,
      },
    };
    return this.header;
  }

  async call(method: string, path: string, postdata?: any) {
    const self = this;
    const domain = config.basedomain;
    if (method === 'GET') {
      return axios.get(`${domain}${path}`, await self.getHeaders()).then((response: any) => {
        self.return.data = response.data;
        return self.return;
      }).catch((response: any) => {
        self.return.status = 'ERROR';
        self.return.msg = response.response.msg;
        if (response.response.data.msg) {
          self.return.msg = response.response.data.msg;
        }
        return self.return;
      });
    }

    return axios.post(`${domain}${path}`, postdata, await self.getHeaders()).then((response: any) => {
      self.return.data = response.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.msg;
      if (response.response.data.msg) {
        self.return.msg = response.response.data.msg;
      }
      return self.return;
    });
  }
}
